<template>
  <Layout class="main-container ship-wrap0">
    <!--    搜索块-->
    <Form
      ref="SearchForm"
      class="search-form-container"
      :model="params"
      label-position="left"
      :label-width="80"
    >
      <div class="search-form-container__content">
        <Row :gutter="16">
          <i-col span="6">
            <FormItem label="订单编号:" prop="OrderCode">
              <Input placeholder="请输入订单编号" v-model="params.OrderCode" />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="会员编号:" prop="MemberCode">
              <Input placeholder="请输入会员编号" v-model="params.MemberCode" />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="商品名称:" prop="ProductName">
              <Input
                placeholder="请输入商品名称"
                v-model="params.ProductName"
              />
            </FormItem>
          </i-col>
          <i-col span="6">
            <FormItem label="收货人:" prop="ReceiveSearchText">
              <Input
                placeholder="请输入收货人姓名/电话/地址"
                v-model="params.ReceiveSearchText"
              />
            </FormItem>
          </i-col>
        </Row>
        <Row :gutter="16">
          <!-- <i-col span="6">
            <FormItem label="运费类型:" prop="FreightType">
              <Select
                clearable
                v-model="params.FreightType"
                placeholder="运费类型"
              >
                <Option
                  v-for="(item, index) in freightTypeEnum"
                  :key="index"
                  :value="item.ID"
                  >{{ item.CN }}</Option
                >
              </Select>
            </FormItem>
          </i-col> -->
          <i-col span="6">
            <FormItem label="订单类型:" prop="OrderType">
              <Select
                clearable
                v-model="params.OrderType"
                placeholder="订单类型"
              >
                <Option
                  v-for="(item, index) in orderTypeEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </i-col>
          <i-col span="12">
            <FormItem label="支付时间:" prop="TempDate">
              <Date-picker
                style="width: 100%"
                clearable
                v-model="params.TempDate"
                ref="date"
                type="datetimerange"
                :options="options"
                placement="bottom-end"
                placeholder="支付日期范围"
                format="yyyy-MM-dd HH:mm:ss"
                @on-change="dateUpdate"
              ></Date-picker>
            </FormItem>
          </i-col>
          <!-- <i-col span="6">
            <FormItem label="测试订单:" prop="CheckTest">
              <Checkbox v-model="CheckTest1">测试订单</Checkbox>
            </FormItem>
          </i-col> -->
        </Row>
      </div>
      <Row type="flex" justify="space-between" align="bottom" class="mt5">
        <i-col span="12" style="text-align: left">
          <!-- <Button
            type="success"
            @click="exportExcel"
            :loading="loading"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-cloud-download"
            >导出发货单</Button
          > -->
          <Button
            type="success"
            class="ml2"
            @click="exportAddress"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-cloud-download"
          >
            导出报表
          </Button>
          <!-- <Button
            type="success"
            class="ml2"
            @click="exportSalesGood"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-cloud-download"
            >导出商品销售单</Button
          > -->
          <Button
            type="success"
            class="ml2"
            @click="openBulkShipment(false)"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-mail"
          >
            批量打印
          </Button>
          <Button
            type="success"
            class="ml2"
            @click="openBulkShipment(true)"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-mail"
          >
            一页多单
          </Button>
          <Button
            type="success"
            class="ml2"
            @click="openBatchShipment(false)"
            v-if="$canAction(['Order_SendEms'])"
            icon="md-mail"
          >
            批量发货
          </Button>
        </i-col>
        <i-col span="12" style="text-align: right">
          <span
            class="search-form-container__arrow_btn"
            @click="$handleHiddenSearch($el)"
          >
            收起
            <Icon type="ios-arrow-up" />
          </span>
          <Button
            class="ml2 search-form-container__button"
            type="primary"
            ghost
            icon="ios-search"
            @click="handleSearch"
          >
            搜索
          </Button>
          <Button
            class="ml2 search-form-container__button"
            @click="handleReset"
          >
            重置
          </Button>
        </i-col>
      </Row>
    </Form>
    <Table
      class="mt10 main-table"
      :loading="tableLoading"
      :height="tableHeight"
      :row-class-name="RowClassName"
      ref="dataTable"
      :columns="columns"
      :data="resObj.DataList"
      highlight-row
      size="small"
      @on-sort-change="onSortChange"
      stripe
      @on-select-all-cancel="handleSelectAllCancel"
      @on-select-cancel="handleSelectCancel"
      @on-select="handleSelect"
      @on-select-all="handleSelect"
    >
      <template slot="FreightTypeCN" slot-scope="{ row }">
        <Tag
          type="border"
          :color="
            row.FreightType === 10
              ? 'red'
              : row.FreightType === 20
              ? 'purple'
              : 'blue'
          "
        >
          {{ row.FreightTypeCN }}
        </Tag>
      </template>
      <template slot="MemberView" slot-scope="{ row }">
        <span
          style="color: green; cursor: pointer"
          @click="handleOpenUser(row.MemberCode)"
        >
          {{ row.MemberCode }}
        </span>
      </template>
      <template slot="action" slot-scope="{ row }">
        <Button
          size="small"
          type="primary"
          v-if="$canAction(['Order_Search'])"
          @click="openDrawer(row)"
        >
          详情
        </Button>
        <Button
          size="small"
          class="ml2"
          type="warning"
          v-if="$canAction(['Order_SendEms'])"
          @click="openShipment(row)"
        >
          发货
        </Button>
      </template>
    </Table>
    <!--    分页-->
    <Row class="mt10">
      <i-col span="24" class="flex-end">
        <Page
          :page-size-opts="[40, 60, 80, 100]"
          :page-size="40"
          :total="resObj.TotalCount"
          :current="resObj.CurrentPage"
          @on-change="handleChangePage"
          size="small"
          show-elevator
          show-total
        />
      </i-col>
    </Row>
    <detail-drawer ref="DetailDrawer" />
    <sales-good-modal ref="SalesGoodModal" />
    <!--    批量打印-->
    <Modal
      title="批量打印"
      class-name="i-modal"
      v-model="isShowBulkShipmentModal"
      loading
    >
      <Form :model="bulkShipmentForm" :label-width="120">
        <FormItem prop="ExpressCode" label="物流公司:">
          <Select
            v-model="bulkShipmentForm.ExpressCode"
            placeholder="请选择物流公司"
            label-in-value
            @on-change="handleChangeExpressCode"
          >
            <Option
              v-for="item in expressList"
              :key="item.Code"
              :value="item.Code"
            >
              {{ item.Name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem prop="ShippingDate" label="发货时间:">
          <DatePicker
            placeholder="请选择发货时间"
            type="datetime"
            v-model="bulkShipmentForm.ShippingDate"
            format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
          ></DatePicker>
        </FormItem>
        <FormItem label="运费方式">
          <RadioGroup v-model="bulkShipmentForm.FreightType">
            <Radio :label="3">
              <span>现付</span>
            </Radio>
            <Radio :label="2">
              <span>到付</span>
            </Radio>
          </RadioGroup>
        </FormItem>
        <FormItem prop="" label="是否打印面单">
          <Checkbox v-model="bulkShipmentForm.print">打印</Checkbox>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="isShowBulkShipmentModal = false">
          取消
        </Button>
        <Button type="primary" @click="handleBulkShipment"> 确定 </Button>
      </div>
    </Modal>

    <Modal
      title="批量发货"
      class-name="i-modal"
      v-model="isShowBatchShipmentModal"
      loading
    >
      <Form :model="batchShipmentForm" :label-width="120">
        <FormItem prop="ExpressCode" label="物流公司:">
          <Select
            v-model="batchShipmentForm.ExpressCode"
            placeholder="请选择物流公司"
            label-in-value
            @on-change="handleChangeExpressCode"
          >
            <Option
              v-for="item in expressList"
              :key="item.Code"
              :value="item.Code"
            >
              {{ item.Name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem prop="ShippingDate" label="发货时间:">
          <DatePicker
            placeholder="请选择发货时间"
            type="datetime"
            v-model="batchShipmentForm.ShippingDate"
            format="yyyy-MM-dd HH:mm:ss"
            style="width: 100%"
          ></DatePicker>
        </FormItem>
        <FormItem prop="" label="运单号码:">
          <Input
            placeholder="请输入物流单号"
            type="text"
            v-model="batchShipmentForm.ShippingCode"
            style="width: 100%"
          />
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="default" @click="isShowBatchShipmentModal = false">
          取消
        </Button>
        <Button type="primary" @click="handleBatchShipment"> 确定 </Button>
      </div>
    </Modal>

    <shipping-modal @on-success="handleShipmentSuccess" ref="ShippingModal" />
    <user-profile-drawer ref="userDrawer" />

    <div id="printHtml" style="display: none">
      <form
        id="printForm"
        action="https://www.kdniao.com/External/PrintOrder.aspx"
        method="post"
        target="_blank"
      >
        <input type="text" id="EBusinessID" name="EBusinessID" />
        <input type="text" value="0" id="IsPreview" name="IsPreview" />
        <input type="text" id="RequestData" name="RequestData" />
        <input type="text" id="DataSign" name="DataSign" />
      </form>
    </div>
  </Layout>
</template>

<script>
import api from "@/api";
import { getStorage, token } from "@/utils/storage";
import bizEnum from "@/libs/bizEnum";
// import { mapActions } from 'vuex';
import Layout from "@/components/layout/index.vue";
import DetailDrawer from "./components/detail-drawer.vue";
import SalesGoodModal from "./components/sales-good-modal.vue";
import ShippingModal from "./components/shipping-modal.vue";
import UserProfileDrawer from "../member/components/member-drawer.vue";
import moment from "moment";
import { Checkbox } from "view-design";
export default {
  name: "WaitShipOrder",
  data() {
    return {
      isShowBulkShipmentModal: false,
      isShowBatchShipmentModal: false,
      tableHeight: 0,
      tableLoading: false,
      loading: false,
      CheckTest1: false,
      params: {
        OrderCode: "",
        MemberCode: "",
        ReceiveSearchText: "",
        ReceiveName: "",
        ReceivePhone: "",
        FreightType: "",
        ProductName: "",
        PayDateBegin: "",
        PayDateEnd: "",
        OrderStatus: 30,
        OrderType: "10,20",
        Environment: "",
        Page: 1,
        PageSize: 40,
        SortName: "PayDate",
        SortOrder: "Desc",
        CheckTest: 0,
      },
      params2: {
        Page: 1,
        PageSize: 100,
        SortName: "Index",
        SortOrder: "ASC",
      },
      columns: [
        { type: "selection", width: 43, align: "center", minWidth: 50 },
        {
          title: "NO",
          type: "index",
          width: 56,
          align: "center",
          minWidth: 50,
        },
        { title: "订单编号", key: "OrderCode", minWidth: 175 },
        // {
        //   title: "会员编号",
        //   key: "MemberCode",
        //   slot: "MemberView",
        //   align: "center",
        //   minWidth: 100,
        //   sortable: "custom",
        // },
        {
          title: "总金额",
          key: "TotalAmount",
          minWidth: 80,
          sortable: "custom",
        },
        {
          title: "商品名",
          key: "ProductName",
          minWidth: 130,
          sortable: "custom",
        },
        {
          title: "商品数量",
          key: "ProductCount",
          minWidth: 90,
          sortable: "custom",
        },
        // {
        //   title: "运费方式",
        //   key: "FreightTypeCN",
        //   slot: "FreightTypeCN",
        //   minWidth: 90,
        //   sortable: "custom",
        // },
        { title: "收货姓名", key: "ReceiveName", minWidth: 95 },
        { title: "收货人电话", key: "ReceivePhone", minWidth: 95 },
        { title: "收货地址", key: "ReceiveAddress", minWidth: 220 },
        {
          title: "付款时间",
          key: "PayDate",
          minWidth: 135,
          sortable: "custom",
        },
        { title: "备注", key: "Remark", minWidth: 150 },
      ],
      resObj: {
        CurrentPage: 1,
        TotalCount: 0,
        TotalPage: 0,
        DataList: [],
      },
      selections: [],
      expressList: [],
      bulkShipmentForm: {
        print: true,
        ExpressCode: "SF",
        ExpressName: "顺丰快递",
        ShippingDate: "",
        isMerge: false,
        FreightType: 2,
      },
      batchShipmentForm: {
        ExpressCode: "SF",
        ExpressName: "顺丰快递",
        ShippingDate: "",
        ShippinCode: "",
      },
      // 日期快捷选择
      options: {
        shortcuts: [
          {
            text: "最近一周",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "最近一个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "最近三个月",
            value() {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              return [start, end];
            },
          },
        ],
      },
    };
  },
  watch: {
    isShowBulkShipmentModal(val) {
      if (val) {
        this.bulkShipmentForm.ShippingDate = moment().format();
        this.GetExpressList();
      } else {
        this.bulkShipmentForm = {
          ExpressCode: "SF",
          ExpressName: "顺丰快递",
          ShippingDate: "",
          isMerge: false,
          print: true,
          FreightType: 2,
        };
      }
    },
    isShowBatchShipmentModal(val) {
      if (val) {
        this.batchShipmentForm.ShippingDate = moment().format();
        this.GetExpressList();
      } else {
        this.batchShipmentForm = {
          ExpressCode: "SF",
          ExpressName: "顺丰快递",
          ShippingDate: "",
          ShippinCode: "",
        };
      }
    },
  },
  created() {
    if (this.$route.meta.tableActionWidth) {
      this.columns.push({
        title: "操作",
        key: "action",
        slot: "action",
        align: "center",
        fixed: "right",
        width: this.$route.meta.tableActionWidth,
      });
    }
    this.loadData();
  },
  mounted() {
    const self = this;
    self.$nextTick(() => {
      self.tableHeight = self.$getTableHeight("main-container");
    });
    window.onresize = function () {
      self.$nextTick(() => {
        self.tableHeight = self.$getTableHeight("main-container");
      });
    };
    document.addEventListener("keydown", this.keydownFun);
    this.$bus.$on("refreshPage", () => {
      if (this.$route.name === this.$options.name) {
        this.params && (this.params = this.$options.data().params);
        this.selections = [];
        self.loadData();
      }
    });
  },
  activated() {
    document.addEventListener("keydown", this.keydownFun);
  },
  deactivated() {
    document.removeEventListener("keydown", this.keydownFun);
  },
  methods: {
    handleChangeExpressCode(e) {
      if (e) {
        this.bulkShipmentForm.ExpressName = e.label;
        this.batchShipmentForm.ExpressName = e.label;
      }
    },
    openBulkShipment(isMerge) {
      this.isShowBulkShipmentModal = true;
      this.bulkShipmentForm.isMerge = isMerge;
    },
    openBatchShipment() {
      this.isShowBatchShipmentModal = true;
    },
    keydownFun(event) {
      const self = this;
      var e = event || window.event;
      if (self.$route.name === self.$options.name && e && e.keyCode === 13) {
        // enter 键
        this.selections = [];
        self.loadData();
      }
    },
    handleShipmentSuccess(obj) {
      const index = this.resObj.DataList.findIndex(
        (item) => item.ID === obj.ID
      );
      if (index > -1) {
        this.resObj.DataList.splice(index, 1);
      }
    },
    openShipment(obj) {
      this.$refs.ShippingModal.ShippingForm = obj;
      this.$refs.ShippingModal.isShow = true;
    },
    handleBulkShipment() {
      if (this.selections.length === 0) {
        this.$Message.error("请选择需要发货的订单");
      } else if (!this.bulkShipmentForm.ExpressCode) {
        this.$Message.error("请选择物流公司");
      } else if (!this.bulkShipmentForm.ShippingDate) {
        this.$Message.error("请选择发货时间");
      } else {
        this.$Spin.show({
          render: (h) => {
            return h("div", [
              h("Icon", {
                class: "demo-spin-icon-load",
                props: {
                  type: "ios-loading",
                  size: 18,
                },
              }),
              h("div", "批量发货中..."),
            ]);
          },
        });
        const ids = this.selections.map((item) => item.ID);
        api
          .BatchDelivery({
            ...this.bulkShipmentForm,
            ids,
          })
          .then((res) => {
            if (this.bulkShipmentForm.print) {
              // 勾选打印面单
              if (res.Data.successList.length) {
                setTimeout(() => {
                  this.printSheet(res.Data.successList);
                }, 800);
              } else {
                this.$Message.success(res.Data.str);
              }
            } else {
              this.$Message.success(res.Data.str);
            }
          })
          .finally(() => {
            this.$Spin.hide();
            this.isShowBulkShipmentModal = false;
            this.loadData();
          });
      }
    },
    handleBatchShipment() {
      if (this.selections.length === 0) {
        this.$Message.error("请选择需要发货的订单");
      } else if (!this.batchShipmentForm.ExpressCode) {
        this.$Message.error("请选择物流公司");
      } else if (!this.batchShipmentForm.ShippingDate) {
        this.$Message.error("请选择发货时间");
      } else {
        this.$Spin.show({
          render: (h) => {
            return h("div", [
              h("Icon", {
                class: "demo-spin-icon-load",
                props: {
                  type: "ios-loading",
                  size: 18,
                },
              }),
              h("div", "批量发货中..."),
            ]);
          },
        });
        const ids = this.selections.map((item) => item.ID);
        api
          .BatchShipping({
            ...this.batchShipmentForm,
            ids,
          })
          .then((res) => {
            this.$Message.success(res.Data.str);
          })
          .finally(() => {
            this.$Spin.hide();
            this.isShowBatchShipmentModal = false;
            this.loadData();
          });
      }
    },
    printSheet(codeArr) {
      api
        .GetBatchPrintInfo({
          orderCodeArr: codeArr,
        })
        .then((res) => {
          document.getElementById("EBusinessID").value = res.Data.EBusinessID;
          document.getElementById("DataSign").value = res.Data.DataSign;
          document.getElementById("RequestData").value = res.Data.RequestData;
          window.printForm.submit();
        })
        .finally(() => {
          this.$Spin.hide();
        });
    },
    exportSalesGood() {
      this.$refs.SalesGoodModal.iscustomer = false;
      this.$refs.SalesGoodModal.Type = 0;
      this.$refs.SalesGoodModal.isShow = true;
    },
    async exportAddress() {
      try {
        const res = await api.GetOrderList({ ...this.params, PageSize: 10000 });
        if (res.Data.DataList.length) {
          const ids = res.Data.DataList.map((item) => item.ID);
          await api.RemarkIsExport({ ids });
          await api.ExportAddressExcelForIDs({ ids });
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        console.error(error);
      }
      // this.$refs.SalesGoodModal.iscustomer = false;
      // this.$refs.SalesGoodModal.Type = 2;
      // this.$refs.SalesGoodModal.isShow = true;
      // if (this.selections.length > 0) {
      //   try {
      //     let selectionsArr = this.selections.map((item) => item.ID);
      //     await api.RemarkIsExport({ ids: selectionsArr });
      //     await api.ExportAddressExcelForIDs({
      //       ids: selectionsArr,
      //     });
      //     // this.$refs.orderTable.exportCsv({
      //     //   filename: `收货地址_${new Date().toLocaleString()}`,
      //     //   columns: [
      //     //     { title: "订单编号", key: "OrderCode", minWidth: 175 },
      //     //     { title: "运费方式", key: "FreightTypeCN", slot: "FreightTypeCN", minWidth: 80 },
      //     //     { title: "收货姓名", key: "ReceiveName", minWidth: 95 },
      //     //     { title: "收货人电话", key: "ReceivePhone", minWidth: 95 },
      //     //     { title: "收货地址", key: "ReceiveAddress", minWidth: 220 },
      //     //     { title: "备注", key: "Remark", minWidth: 150 },
      //     //   ],
      //     //   data: this.exportData,
      //     // });
      //   } catch (error) {
      //     this.$Notice.error({ title: "错误", desc: error });
      //     console.error(error);
      //   }
      // } else {
      //   this.$Message.error("请选择需要导出的订单");
      //   // this.$refs.SalesGoodModal.iscustomer = false;
      //   // this.$refs.SalesGoodModal.Type = 2;
      //   // this.$refs.SalesGoodModal.isShow = true;
      // }
    },
    async exportExcel() {
      // this.$refs.SalesGoodModal.Type = 1;
      // this.$refs.SalesGoodModal.isShow = true;
      if (this.selections.length === 0) {
        // this.$Message.error('请选择要导出的订单');
        this.$refs.SalesGoodModal.Type = 1;
        this.$refs.SalesGoodModal.isShow = true;
      } else {
        this.loading = true;
        await api.OriginExportExcel({
          ids: this.selections.map((item) => item.ID),
          exportName: `发货单${moment().format("YYYY-MM-DD HH:mm:ss")}.xls`,
        });
        this.loading = false;
        this.$Message.success("订单导出成功");
      }
    },
    onSortChange(result) {
      this.params.SortName =
        result.key === "FreightTypeCN" ? "FreightType" : result.key;
      this.params.SortOrder = result.order !== "normal" ? result.order : "desc";
      this.loadData();
    },
    // 日期变化方法
    dateUpdate(value) {
      this.params.PayDateBegin = value[0];
      this.params.PayDateEnd = value[1];
    },
    handleSelectCancel(_selection, row) {
      const index = this.selections.findIndex((item) => item.ID === row.ID);
      if (index > -1) {
        this.selections.splice(index, 1);
      }
    },
    handleSelectAllCancel() {
      this.selections = this.selections.filter(
        (obj) => !this.resObj.DataList.some((obj1) => obj1.ID === obj.ID)
      );
    },
    handleSelect(selection) {
      let bigArr = [...this.selections, ...selection];
      let smallArr = [];
      bigArr.some((item) => {
        if (smallArr.findIndex((res) => res.ID === item.ID) === -1) {
          smallArr.push(item);
        }
      });
      this.selections = smallArr;
    },
    openDrawer(row, isEdit) {
      this.$refs.DetailDrawer.obj = row;
      this.$refs.DetailDrawer.isShow = true;
      this.$refs.DetailDrawer.isEdit = isEdit;
    },
    handleChangePageSize(pagesize) {
      this.selections = [];
      this.params.Page = 1;
      this.params.PageSize = pagesize;
      this.loadData();
    },
    handleChangePage(page) {
      this.params.Page = page;
      this.loadData();
    },
    handleSearch() {
      this.params.Page = 1;
      this.selections = [];
      this.loadData();
    },
    handleReset() {
      this.$refs.SearchForm.resetFields();
      this.selections = [];
      this.loadData();
    },
    // 获取物流配置信息
    async GetExpressList() {
      try {
        const res = await api.GetExpressList(this.params2);
        if (res.Data) {
          this.expressList = res.Data.DataList;
        }
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
      }
    },
    async loadData() {
      this.tableLoading = true;
      if (this.CheckTest1) {
        this.params.CheckTest = 1;
      } else {
        this.params.CheckTest = 0;
      }
      try {
        const res = await api.GetOrderList(this.params);
        res.Data.DataList.forEach((item) => {
          if (this.selections.find((cell) => cell.ID === item.ID)) {
            item._checked = true;
          } else {
            item._checked = false;
          }
        });
        this.resObj = res.Data;
        this.tableLoading = false;
      } catch (error) {
        this.$Notice.error({ title: "错误", desc: error });
        this.tableLoading = false;
      } finally {
        this.selections = [];
        this.bulkShipmentForm = {
          print: true,
          ExpressCode: "SF",
          ExpressName: "顺丰快递",
          ShippingDate: "",
          isMerge: false,
          FreightType: 2,
        };
        this.batchShipmentForm = {
          ExpressCode: "SF",
          ExpressName: "顺丰快递",
          ShippingDate: "",
          ShippinCode: "",
        };
      }
    },
    async handleOpenUser(MemberID) {
      try {
        const res = await api.GetMemberByCode({
          code: MemberID,
        });
        if (res.Status === 100) {
          this.$refs.userDrawer.obj = res.Data;
          this.$refs.userDrawer.isShow = true;
        } else {
          this.$Message.error(res.Msg);
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        // TODO: 记录错误日志
      }
    },
    // 选中行样式
    RowClassName(row) {
      if (this.selections.find((item) => item.ID === row.ID)) {
        return "tdSelected";
      }
      return "";
    },
  },
  computed: {
    headers() {
      return { Authorization: "Bearer " + getStorage(token) };
    },
    exportData() {
      const data = JSON.parse(JSON.stringify(this.selections));
      return data.map((item) => {
        item.OrderCode = `="${item.OrderCode}"`;
        item.ReceivePhone = `="${item.ReceivePhone}"`;
        return item;
      });
    },
    freightTypeEnum() {
      return bizEnum.FreightTypeEnum;
    },
    orderTypeEnum() {
      return bizEnum.OrderTypeEnum;
    },
  },
  components: {
    DetailDrawer,
    SalesGoodModal,
    ShippingModal,
    UserProfileDrawer,
    Layout,
    Checkbox,
  },
};
</script>

<style lang="less">
.ship-wrap0 {
  .ivu-table .demo-table-error-row td {
    background-color: #ffd591 !important;
    color: rgb(0, 0, 0);
  }
  .ivu-table-row .tdSelected td {
    background: #c7dae6 !important;
    color: red !important;
  }
  .ivu-modal-confirm-body {
    div {
      word-break: break-all;
    }
  }
}
</style>
